import type {FC} from 'react';
import clsx from 'clsx';

interface Props {
	size?: 'small' | 'medium' | 'large';
	color?: 'dark' | 'light';
}

export const Spinner: FC<Props> = ({size, color = 'dark'}) => {
	let spinnerSize = '';
	switch (size) {
		case 'small':
			spinnerSize = 'h-4 w-4 border-2';
			break;
		case 'medium':
			spinnerSize = 'h-6 w-6 border-4';
			break;
		case 'large':
			spinnerSize = 'h-8 w-8 border-4';
			break;
		default:
			spinnerSize = 'h-6 w-6 border-4';
			break;
	}

	const spinnerColor = color === 'dark' ? 'border-primary-dark' : 'border-white';

	return (
		<div
			className={clsx(
				spinnerColor,
				'border-r-transparent',
				'inline-block',
				'animate-spin',
				'rounded-full',
				'border-solid',
				'align-[-0.125em]',
				'motion-reduce:animate-[spin_1.5s_linear_infinite]',
				spinnerSize,
			)}
			role="status"
		>
			<span className={clsx('sr-only')}>Loading...</span>
		</div>
	);
};
