import {groq} from 'next-sanity';
import type {Slug} from 'sanity';
import type {SiteSettingsSchema} from 'schema/singletons/site-settings';
import type {LinkableDocumentTypes} from 'utils';

export const LINK_REFERENCES_QUERY = groq`
*[(_type == "page" || _type == "frontpage") && _id == $id][0]{
	_id,
	_type,
	slug,
}
`;

export type LinkReferencesQueryResult = {
	_id: string;
	_type: LinkableDocumentTypes;
	slug?: Slug;
} | null;

export const SITE_SETTINGS_QUERY = groq`
*[_type == "siteSettings"][0]{
	...
}`;

export type SiteSettingsQueryResult = SiteSettingsSchema | null;
