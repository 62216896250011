'use client';

import type {FC} from 'react';
import {useLiveQuery} from 'next-sanity/preview';

import {FullPageSpinner} from 'components/full-page-spinner';

import {HeaderClient} from './client';
import type {SiteSettingsRefQueryResult} from './query';
import {siteSettingsRefQuery} from './query';

export const HeaderDraft: FC = () => {
	const [siteSettings, loading] = useLiveQuery<SiteSettingsRefQueryResult>(
		null,
		siteSettingsRefQuery,
	);

	if (loading) {
		return <FullPageSpinner />;
	}

	if (!siteSettings) {
		return null;
	}

	return <HeaderClient siteSettings={siteSettings} />;
};
