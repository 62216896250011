import {groq} from 'next-sanity';
import type {Slug} from 'sanity';
import type {SiteSettingsSchema} from 'schema/singletons/site-settings';

/**
 * Fetches the data from site settings that is needed for the header
 */
export const siteSettingsRefQuery = groq`
*[_type == "siteSettings" && !(_id in path('drafts.**'))][0]{
	...,
	headerLinks[] {
		_key,
		_type == 'singleLink' => @->{
			_type,
			title,
			slug
		},
		_type == 'dropdownLinks' => @{
			_type,
			title,
			links[]->{
				_type,
				title,
				slug
			}
		},
	},
}
`;

export type HeaderLinkType = {
	_type: 'page' | 'dropdownLinks' | 'frontpage';
	title: string;
	slug: Slug;
	links?: HeaderLinkType[];
};

export type SiteSettingsRefQueryResult = SiteSettingsSchema | null;
