import type {FC} from 'react';
import clsx from 'clsx';

import {Spinner} from 'components/spinner';

export const FullPageSpinner: FC = () => {
	return (
		<div className={clsx('flex', 'h-screen', 'items-center', 'justify-center')}>
			<Spinner size="large" />
		</div>
	);
};
