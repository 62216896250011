'use client';

import type {FC} from 'react';
import type {
	NextImageBlockProps,
	NextImageFillProps,
	NextImageInlineProps,
} from '@selvklart/sanity-next-image';
import {NextImageBlock, NextImageFill, NextImageInline} from '@selvklart/sanity-next-image';

export const ImageBlockClient: FC<NextImageBlockProps> = (props) => {
	return <NextImageBlock {...props} />;
};

export const ImageFillClient: FC<NextImageFillProps> = (props) => {
	return <NextImageFill {...props} />;
};

export const ImageInlineClient: FC<NextImageInlineProps> = (props) => {
	return <NextImageInline {...props} />;
};
