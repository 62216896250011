'use client';

import type {FC} from 'react';
import {useLiveQuery} from 'next-sanity/preview';
import type {SiteSettingsQueryResult} from 'utils/sanity.queries';
import {SITE_SETTINGS_QUERY} from 'utils/sanity.queries';

import {FullPageSpinner} from 'components/full-page-spinner';

import {FooterRender} from './render';

interface Props {
	isDraft: boolean;
}

export const FooterDraft: FC<Props> = ({isDraft}) => {
	const [siteSettings, loading] = useLiveQuery<SiteSettingsQueryResult>(
		null,
		SITE_SETTINGS_QUERY,
	);

	if (loading) {
		return <FullPageSpinner />;
	}

	if (!siteSettings) {
		return null;
	}

	return <FooterRender siteSettings={siteSettings} isDraft={isDraft} />;
};
