import type {FC, ReactNode} from 'react';
import type {SanityImage} from '@selvklart/sanity-next-image';
import clsx from 'clsx';
import Link from 'next/link';
import type {SiteSettingsSchema} from 'schema/singletons/site-settings';

import {ImageInlineClient} from 'components/image/client';

import {Logo} from './logo';

interface Props {
	siteSettings: SiteSettingsSchema;
	isDraft: boolean;
}

export const FooterRender: FC<Props> = ({siteSettings}) => {
	if (!siteSettings) {
		return null;
	}
	const {organization, contactInfo, socialMedia} = siteSettings;

	return (
		<footer className={clsx('bg-primary-dark')}>
			<div
				className={clsx(
					'relative',
					'flex',
					'items-center',
					'justify-center',
					'py-10',
					'md:py-14',
					'text-white',
				)}
			>
				<div
					className={clsx(
						'grid',
						'md:grid-cols-4',
						'w-full',
						'max-w-7xl',
						'px-6',
						'lg:px-8',
						'gap-24',
					)}
				>
					<div className={clsx('flex', 'items-center')}>
						<Link href="/" className={clsx('px-1.5')}>
							<span className={clsx('sr-only')}>{siteSettings?.title}</span>
							<Logo />
						</Link>
					</div>
					<div>
						<FooterTitle>{organization?.name}</FooterTitle>

						<h3 className={clsx('font-bold')}>Besøksadresse:</h3>
						<ul className={clsx('mb-4')}>
							{contactInfo?.addresses?.map((address) => (
								<li key={`${address.streetName}-${address.postalCode}`}>
									{address.streetName}, {address.postalCode} {address.city}{' '}
									{address.description ? `(${address.description})` : ''}
								</li>
							))}
						</ul>

						<h3 className={clsx('font-bold')}>Organisasjonsnummer:</h3>
						<p>{organization?.number}</p>
					</div>
					<div>
						{contactInfo && <FooterTitle>Kontakt</FooterTitle>}

						<h3 className={clsx('font-bold')}>Telefon:</h3>
						<ul className={clsx('mb-4')}>
							{contactInfo?.phoneNumbers?.map((phone) => (
								<li key={phone.number}>
									<a
										href={`tel:${phone.number?.replaceAll(' ', '')}`}
										className={clsx(
											'hover:text-secondary',
											'focus:text-secondary',
										)}
									>
										{phone.number}{' '}
										{phone.description && `(${phone.description})`}
									</a>
								</li>
							))}
						</ul>

						<h3 className={clsx('font-bold')}>Epost:</h3>
						<p>
							<a
								href={`mailto:${contactInfo?.email?.replaceAll(' ', '')}`}
								className={clsx('hover:text-secondary', 'focus:text-secondary')}
							>
								{contactInfo?.email}
							</a>
						</p>
					</div>

					<ul className={clsx('flex', 'gap-12', 'md:justify-end', 'items-center')}>
						{socialMedia?.map((social) => (
							<li key={social.url}>
								<a
									href={social.url ?? '#'}
									target="_blank"
									rel="noreferrer"
									className={clsx('underline', 'hover:no-underline')}
								>
									<ImageInlineClient
										image={social.icon as SanityImage}
										alt={social.title ?? ''}
										className={clsx('h-12', 'w-auto')}
									/>
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</footer>
	);
};

const FooterTitle = ({children}: {children: ReactNode}) => (
	<h2 className={clsx('text-xl', 'md:text-2xl', 'text-white', 'mb-2', 'md:mb-4')}>{children}</h2>
);
